import React from "react";

import logo from "../img/logo.svg";

const Header = () => (
  <section className="container">
    <div className="logo">
      <img src={logo} alt="RentACar Logo" />
    </div>
  </section>
);

export default Header;
