import config from ".";
import axios from "axios";

const api = axios.create({
  baseURL: config.api.path
});

api.interceptors.response.use(
  resp => resp,
  err => {
    if (err.response && err.response.data) {
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        let errors = {};

        apiErrors.forEach(error => {
          try {
            errors[camelize(error.propertyName)] = error.message;
          } catch (e) {}
        });

        return Promise.reject(errors);
      }
      return Promise.reject({ ...err.response.data });
    }

    return Promise.reject(err);
  }
);

const camelize = str => {
  if (!str) return;
  return str
    .split(".")
    .map(part => part.charAt(0).toLowerCase() + part.slice(1))
    .join(".");
};

export default api;
