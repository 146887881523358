import React from "react";

import notFound from "../img/error-generic.png";

const EcranNoir = () => {
  return (
    <section className="container">
      <section className="box-main">
        <img src={notFound} className="box-logo" alt="Not found illustration" />
        <h2>Oups !</h2>
        <p className="box-copy">
          Désolé, le dossier de paiement que vous cherchez est introuvable.{" "}
          <br />
          En attendant, n'hésitez pas à vous rendre en agence ou à consulter
          notre site internet.
        </p>
      </section>
    </section>
  );
};

export default EcranNoir;
