import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import config from "./config";
import App from "./app";

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration()
  ],
  tracesSampleRate: 1.0
});

ReactDOM.render(<App />, document.getElementById("root"));
