import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";

import matomo from "./config/matomo";

import Dispatch from "./pages/dispatch";
import EcranNoir from "./pages/404";
import Footer from "./components/footer";
import Header from "./components/header";

import "./less/style.less";

const App = props => {
  return (
    <MatomoProvider value={matomo}>
      <Router>
        <div className="app-wrapper">
          <Header />
          <div className="app-content">
            <Switch>
              <Route path="/:paymentId/" component={Dispatch} />
              <Route path="*" component={EcranNoir} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </MatomoProvider>
  );
};

export default App;
