import React from "react";

import config from "../config";

const Footer = () => (
  <footer className="bg-white">
    <a
      href={config?.static.terms}
      title="Accéder aux Conditions générales de location"
    >
      Conditions générales de location
    </a>
    <a
      href={config?.static.gdpr}
      title="Accéder aux informations relatives à la protection des données"
    >
      Protection des données
    </a>
  </footer>
);

export default Footer;
