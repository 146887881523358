import React, { useEffect, useState } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Loader from "../components/loader";
import Agency from "../components/agency";
import Expired from "../components/expired";
import Error from "../components/error";
import Payline from "../components/payline";

import EcranNoir from "../pages/404";

import securePayment from "../img/secure-payment.png";
import api from "../config/api";

dayjs.locale("fr");

//This component is the root of the payment process.
//When we get it, we check query the API to check the state of the payment
//Then we display either the payment form (is everything is OK), the confirmation (if payment is done), or an error

const PaymentDispatch = ({ match }) => {
  const { trackPageView, trackEvent } = useMatomo();

  const { params } = match;
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const paymentId = params.paymentId;

  const hasToPay = payment => payment && payment.paymentStatus === "ToPay";
  const hasPaid = payment => payment && payment.paymentStatus === "Paid";
  const hasSynchronized = payment =>
    payment && payment.paymentStatus === "Synchronized";
  const isDebitPayment = payment =>
    payment && payment?.debitRequest?.status === "Success";

  //Todo, one day maybe : read the locale for the client
  const moneyFormatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR"
  });

  //On page load
  useEffect(() => {
    getPayment(paymentId);
  }, [paymentId]);

  const getPayment = paymentId => {
    setLoading(true);

    console.log("Checking payment with ID", paymentId);

    //Check payment status
    api
      .get(paymentId)
      .then(resp => resp.data)
      .then(data => {
        setPayment(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Checking payment after its done
  const checkPayment = state => {
    console.log("Got state", state, paymentId);

    getPayment(paymentId);
  };

  if (loading) return <Loader />;
  if (!paymentId || !payment) return <EcranNoir />;
  if (payment?.errorCode === 29001) return <Expired />;

  return (
    <>
      <div className="container">
        <h1>Paiement de Forfait Post-Stationnement</h1>
      </div>
      {hasToPay(payment) ? (
        <>
          <section className="container">
            <section className="payment-copy">
              <p className="payment-description">
                Suite à la location que vous avez effectuée chez Rent A Car du{" "}
                <strong>
                  {dayjs(payment.businessData.booking.pickupDate).format(
                    "DD MMMM"
                  )}{" "}
                  au{" "}
                  {dayjs(payment.businessData.booking.dropoffDate).format(
                    "D MMMM YYYY"
                  )}
                </strong>{" "}
                avec le véhicule immatriculé{" "}
                <strong>{payment.businessData.vehicle.plateNumber}</strong>,
                nous avons reçu un avis de Forfait Post-Stationnement (FPS) vous
                concernant le{" "}
                <strong>
                  {dayjs(payment.businessData.fine.fineDate).format(
                    "D MMMM YYYY [à] HH:mm"
                  )}
                </strong>
              </p>
            </section>
            <section className="payment-meta">
              <div className="payment-date info-box">
                <h3>Date et heure de l'infraction constatée</h3>
                <div className="info">
                  <span>
                    {dayjs(payment.businessData.fine.fineDate).format(
                      "DD MMMM YYYY"
                    )}
                  </span>
                  <span>
                    {dayjs(payment.businessData.fine.fineDate).format("HH:mm")}
                  </span>
                </div>
              </div>
              <div className="payment-amount info-box">
                <h3>Montant dû (incl. frais de dossier)</h3>
                <div className="info">
                  <span>{payment.businessData.invoice.invoiceAmount}€</span>
                </div>
              </div>
            </section>
            <ul className="payment-downloads">
              <li>
                <a
                  href={payment.businessData.fine.fileUrl}
                  title="Télécharger le Forfait de Post-Stationnement"
                >
                  Télécharger le Forfait de Post-Stationnement
                </a>
              </li>
              <li>
                <a
                  href={payment.businessData.invoice.fileUrl}
                  title="Télécharger la facture"
                >
                  Télécharger la facture
                </a>
              </li>
            </ul>
            <section className="box-main payment-box">
              <img
                className="box-logo"
                src={securePayment}
                alt="Paiement sécurisé"
              />
              <h2>Paiement Sécurisé</h2>
              <Payline paymentId={paymentId} onComplete={checkPayment} />
            </section>
          </section>
          <section className="bg-grey-5">
            <Agency agency={payment.businessData.agency} />
          </section>
        </>
      ) : hasPaid(payment) || hasSynchronized(payment) ? (
        <>
          <section className="container">
            <section className="box-main">
              <img
                className="box-logo"
                src={securePayment}
                alt="Paiement sécurisé"
              />
              {isDebitPayment(payment) ? (
                <>
                  <h2>Information de règlement</h2>
                  <p className="box-copy">
                    Suite à une location que vous avez effectuée chez Rent A
                    Car, nous avons reçu un avis de Forfait Post-Stationnement
                    (FPS) vous concernant. Un débit correspondant au montant du
                    FPS auquel s’ajoutent les frais de gestion du dossier et la
                    TVA a été effectué.
                    <br />
                    Veuillez trouver ci-dessous une copie du FPS ainsi que votre
                    facture acquittée.
                  </p>
                </>
              ) : (
                <>
                  <h2>Votre paiement a bien été reçu</h2>
                  {hasSynchronized(payment) ? (
                    <p className="box-copy">
                      Nous vous confirmons avoir reçu le paiement de votre
                      forfait post stationnement pour votre location.
                      <br />
                      Vous trouverez ci-dessous vos documents que vous pouvez
                      télécharger.
                    </p>
                  ) : (
                    <p className="box-copy">
                      Nous vous confirmons avoir reçu le paiement de votre
                      Forfait Post Stationnement pour votre location.
                      <br />
                      Un mail de confirmation va vous être envoyé.
                    </p>
                  )}
                </>
              )}
              <div className="payment-confirmation">
                <div>
                  <h4>N° de facture</h4>
                  <span>{payment.businessData.invoice.invoiceId}</span>
                </div>
                <div>
                  <h4>Date et heure de paiement</h4>
                  <span>
                    {dayjs(payment.transactionDateTime).format(
                      "DD MMMM YYYY [à] HH:mm"
                    )}
                  </span>
                </div>
                <div>
                  <h4>Montant</h4>
                  <span>
                    {moneyFormatter.format(
                      payment.businessData.invoice.invoiceAmount
                    )}{" "}
                    <sup>TTC</sup>
                  </span>
                </div>
                {!isDebitPayment(payment) && (
                  <div>
                    <h4>Statut</h4>
                    <span className="success">Payé</span>
                  </div>
                )}
              </div>
              <ul className="payment-downloads">
                <li>
                  <a
                    href={payment.businessData.fine.fileUrl}
                    title="Télécharger le Forfait de Post-Stationnement"
                  >
                    Télécharger le Forfait de Post-Stationnement
                  </a>
                </li>
                <li>
                  <a
                    href={payment.businessData.invoice.fileUrl}
                    title="Télécharger la facture"
                  >
                    Télécharger la facture
                  </a>
                </li>
              </ul>
            </section>
          </section>
          {isDebitPayment(payment) && (
            <section className="bg-grey-5">
              <Agency agency={payment.businessData.agency} />
            </section>
          )}
        </>
      ) : (
        <Error />
      )}
    </>
  );
};

export default PaymentDispatch;
