//Dotenv is not necessary because parcel bundles it by default

export default {
  port: process.env.PORT || 3000,
  environment: process.env.NODE_ENV || "dev",
  static: {
    terms: process.env.TERMS_URL || "https://www.rentacar.fr/cgv",
    gdpr:
      process.env.GDPR_URL ||
      "https://www.rentacar.fr/utiles/56-declaration-de-confidentialite"
  },
  api: {
    path: process.env.API_PATH
  },
  matomo: {
    url: process.env.MATOMO_URL || "https://webstats.euw3.rentacar.fr/",
    siteId: process.env.MATOMO_SITE_ID || "4"
  },
  sentry: {
    dsn:
      process.env.SENTRY_DSN ||
      "https://c94687c7bb8a4b528ed69369317608e7@sentry.euw3.rentacar.fr/33",
    environment: process.env.SENTRY_ENVIRONMENT || "dev"
  }
};
