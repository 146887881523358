import React from "react";

import notFound from "../img/error-generic.png";

const Expired = () => {
  return (
    <section className="container">
      <section className="box-main">
        <img src={notFound} className="box-logo" alt="Not found illustration" />
        <h2>Lien expiré</h2>
        <p className="box-copy">
          Le lien de paiement de votre Forfait Post Stationnement en ligne a
          expiré.
          <br />
          Nous vous conseillons de vous rapprocher de votre agence pour la
          régularisation de votre facture.
        </p>
      </section>
    </section>
  );
};

export default Expired;
