import React from "react";

import notFound from "../img/error-generic.png";

const Error = () => {
  return (
    <section className="container">
      <section className="box-main">
        <img src={notFound} className="box-logo" alt="Not found illustration" />
        <h2>Oups !</h2>
        <p className="box-copy">
          Une erreur est survenue, veuillez réessayer dans quelques instants.
          <br />
          Si le problème persiste nous vous conseillons de vous rapprocher de
          votre agence.
        </p>
      </section>
    </section>
  );
};

export default Error;
