import React from "react";

const Agency = ({ agency }) => {
  return (
    <section className="container payment-explanation">
      <h3>Contactez votre agence</h3>
      <span>{agency.name}</span>
      <div className="flex agency">
        <div>
          <h4>Adresse</h4>
          <span>{agency.address}</span>
        </div>
        <div>
          <h4>Contact</h4>
          <span>{agency.phone}</span>
        </div>
      </div>
      <h3>Qu'est-ce que le FPS ?</h3>
      <p>
        Le forfait post-stationnement (FPS), établi au nom du titulaire de la
        carte grise du Véhicule, est dû suite à non-paiement ou à une
        insuffisance de paiement par vos soins de la redevance applicable dans
        une zone où le stationnement est payant.
      </p>
      <p>
        En votre qualité de locataire du véhicule, conformément aux dispositions
        des Conditions Générales de Location, il vous appartient de régler ce
        forfait post stationnement et les frais de dossier mentionnés aux
        Informations Générales.
      </p>
    </section>
  );
};

export default Agency;
