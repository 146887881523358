import React, { useEffect, useState } from "react";
import { PaylineWidget } from "react-payline";
import Loader from "./loader";
import config from "../config";
import api from "../config/api";

const Payline = ({ paymentId, onComplete }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [checking, setChecking] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const baseUrl =
      config.environment === "production"
        ? "https://payment.payline.com"
        : "https://homologation-payment.payline.com";
    const script = document.createElement("script");
    const link = document.createElement("link");

    script.src = `${baseUrl}/scripts/widget-min.js`;
    script.onload = () => setHasLoaded(true);

    link.href = `${baseUrl}/styles/widget-min.css`;
    link.rel = "stylesheet";

    document.body.appendChild(script);
    document.body.appendChild(link);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(link);
    };
  }, []);

  useEffect(() => {
    setFetching(true);

    api
      .post("/payline/token", {
        cancelUrl: window.location.href,
        returnUrl: window.location.href,
        paymentId
      })
      .then(resp => resp.data)
      .then(data => {
        if (data.errorCode) return;
        setToken(data);
      })
      .finally(() => setFetching(false));
  }, [paymentId]);

  const _onComplete = state => {
    if (state === "PAYMENT_SUCCESS") {
      setChecking(true);

      return api
        .post("/payline/check", {
          Token: token,
          PaymentId: paymentId
        })
        .then(data => {
          onComplete(state);
        })
        .finally(() => setChecking(false));
    } else {
      //This case should not really concern us : if the final state is failing,
      //Payline should display the exact error and we cannot continue further
      //However, it seems like the payment can actually work and we get stuck here
      console.error(
        "Got a payment error from PayLine :",
        state,
        paymentId,
        token
      );
    }
  };

  if (!hasLoaded || fetching) return <Loader />;

  return (
    <>
      {token ? (
        <>
          {checking ? (
            <strong>Vérification en cours...</strong>
          ) : (
            <PaylineWidget
              token={token}
              template="column"
              embeddedRedirectionAllowed={true}
              onFinalStateHasBeenReached={({ state }) => _onComplete(state)}
            />
          )}
        </>
      ) : (
        <strong className="error">
          Une erreur s'est produite, veuillez vous rapprocher de votre agence
        </strong>
      )}
    </>
  );
};

export default Payline;
